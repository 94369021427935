<template>
    <div class="app-provider-branding">
        <div class="head">
            <app-dropdown-select
                v-model="category"
                :options="color_scheme_categories"

                label="Category:"
                :label-inline="true"

                key-value="key"
                key-title="title"

                @change="changeCategory"
            ></app-dropdown-select>
        </div>

        <div class="colors">
            <app-branding-color-scheme v-for="(scheme, key) in schemes" :key="key"
                v-model="scheme.colors"
                :states="scheme.states"
                :original="original[key]"

                :title="scheme.title"

                :clipboard="clipboard"
                :disabled="processing"

                @copy="copyScheme"
                @past="onPastScheme"
                @change="onChangeScheme(key, $event)"
            ></app-branding-color-scheme>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appDropdownSelect from '@/components/app-dropdown-select'

import appBrandingColorScheme from '@/components/app-branding-color-scheme'

/**
 * @todo переименовать в app-pranding
 */
export default {
    props: {
        value:    { type: Object, required: true },
        original: { type: Object, required: true },
    },

    components: {
        appDropdownSelect,

        appBrandingColorScheme,
    },

    data() {
        return {
            schemes: {},
            category: null,

            clipboard: {},

            self: false,

            processing: false,
        }
    },

    created() {
        this.initSchemes()
    },

    methods: {
        initSchemes() {
            if (this.category === null) {
                this.category = Object.values(this.color_scheme_categories)[0].key
            }

            let schemes = {}

            if (this.category && this.category in this.color_scheme) {
                const elements = this.color_scheme[ this.category ].elements

                for (const key in elements) {
                    schemes[ key ] = {
                        ...elements[ key ],
                        colors: key in this.value ? [...this.value[key]] : [],
                    }
                }
            }

            this.schemes = schemes
        },

        changeCategory() {
            this.initSchemes()
        },

        copyScheme(scheme) {
            this.clipboard = scheme
        },
        onPastScheme() {
            this.clipboard = {}
        },

        onChangeScheme(key, colors) {
            this.self = true

            this.$emit('input', {
                ...this.value,
                [ key ]: colors,
            })
        },
    },

    watch: {
        value: {
            handler() {
                if (this.self) {
                    this.self = false
                } else {
                    this.initSchemes()
                }
            },
            deep: false,
        },
    },

    computed: {
        ...mapGetters([
            'color_scheme',
            'color_scheme_categories',
        ]),
    },
}
</script>

<style lang="scss">
.app-provider-branding {
    .head {
        background: var(--color-table-head-bg);

        .app-dropdown-select {
            font-size: 18px;
            padding: 0 24px;

            .trigger {
                background: transparent;
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .app-provider-branding {
        .head {
            .app-dropdown-select {
                padding: 0 16px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-provider-branding {
        .head {
            position: relative;

            .app-dropdown-select {
                padding: 0 8px;
                position: static;
            }
        }
    }
}
</style>