<template>
    <div class="app-checkbox-group">
        <h5 v-if="label">{{ label }}</h5>

        <div class="options">
            <app-checkbox v-for="(option, key) in options" :key="key"
                :value="values[ option[keyValue] ]
                    || (options_disabled[ option[ keyValue ] ] && options_disabled[ option[ keyValue ] ].checked)
                    || (options_readonly[ option[ keyValue ] ] && options_readonly[ option[ keyValue ] ].checked)
                "

                :disabled="option_disabled(option, disabled)"
                :readonly="option_readonly(option, readonly)"

                @change="onChange(option, $event)"
            >{{ option[keyTitle] }}</app-checkbox>
        </div>
    </div>
</template>

<script>
import appCheckbox from '@/components/app-checkbox'

export default {
    props: {
        value:       { type: Array, required: true },

        options:         { type: [Array, Object], required: true },
        optionsDisabled: { type: [Array, Object], default: () => ({}) },
        optionsReadonly: { type: [Array, Object], default: () => ({}) },

        keyValue:    { type: String, default: 'id' },
        keyTitle:    { type: String, default: 'name' },
        keyReadonly: { type: String, default: null },
        keyDisabled: { type: String, default: null },

        label:       { type: String, default: '' },

        disabled:    { type: Boolean, default: false },
        readonly:    { type: Boolean, default: false },
    },

    components: {
        appCheckbox,
    },

    data() {
        return {
            values: {},

            self: null,
        }
    },

    created() {
        this.initValues()
    },

    methods: {
        initValues() {
            let values = {}

            for (let i = 0, len = this.options_arr.length; i < len; i++) {
                const option = this.options_arr[i]

                values[ option[ this.keyValue ] ] = this.value.indexOf(option[ this.keyValue ]) > -1
            }

            this.values = values
        },

        onChange(option, checked) {
            let value = []

            this.values[ option[ this.keyValue ] ] = checked

            for (const key in this.values) {
                if (this.values[ key ]) {
                    value.push(key)
                }
            }

            this.self = JSON.stringify(value)

            this.$emit('input', value)
        },

        option_readonly(option, default_value) {
            if (this.keyReadonly && (this.keyReadonly in option)) {
                return option[this.keyReadonly]
            }
            return option[this.keyValue] in this.options_readonly || default_value
        },

        option_disabled(option, default_value) {
            if (this.keyDisabled && (this.keyDisabled in option)) {
                return option[this.keyDisabled]
            }
            return option[this.keyValue] in this.options_disabled || default_value
        },
    },

    watch: {
        value() {
            if (!(this.self && this.self == JSON.stringify(this.value))) {
                this.initValues()
            }

            this.self = null
        },

        options() {
            this.initValues()
        },

        optionsDisabled() {
            this.initValues()
        },

        optionsReadonly() {
            this.initValues()
        },
    },

    computed: {
        options_arr() {
            return Array.isArray(this.options) ? this.options : Object.values(this.options)
        },

        options_disabled() {
            return Array.isArray(this.optionsDisabled)
                ? this.optionsDisabled.reduce((options, key) => {
                    options[key] = { checked: true }
                    return options
                }, {})
                : this.optionsDisabled
        },
        
        options_readonly() {
            return Array.isArray(this.optionsReadonly)
                ? this.optionsReadonly.reduce((options, key) => {
                    options[key] = { checked: true }
                    return options
                }, {})
                : this.optionsReadonly
        },
    },
}
</script>

<style lang="scss">
.app-checkbox-group {
    h5 {
        font-size: 20px;
        line-height: 22px;
        text-align: left;
    }

    .options {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: -6px;

        .app-checkbox {
            margin: 30px 5px 0 0;
        }
    }
}

@media (max-width: $tablet-size) {
    .app-checkbox-group {
        h5 {
            font-size: 18px;
            line-height: 20px;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-checkbox-group {
        h5 {
            font-size: 16px;
            line-height: 22px;
        }

        .options {
            .app-checkbox {
                margin: 24px 5px 0 0;
            }
        }
    }
}
</style>